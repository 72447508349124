/* global $ */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import googleVar from '../../var/google.json';
import manageDeviceId from '../utils/manageDeviceId';

// For local TOPNAVBAR TESTING and FALLBACK
// import navbarDataLocal from '../../build/navbarDev.json';
import navbarData from '../../bff-data/navbar.json';

// For Feature Branch
import featureNavbar from '../../build/featureNavbarData.json';

const topZIndex = 2147483645;

const runtime = typeof document === 'object';

const NavbarV6 = styled.nav`
  background-color: rgba(255, 255, 255) !important;
  padding: 8px 16px;
  margin: 0;
  width: 100%;

  &.nav-primary {
    position: sticky;
    top: 0;
    z-index: ${topZIndex - 1};
    border-bottom: 1px solid #e6e6e6;
    &.activeMenu {
      background-color: #ffffff !important;
      position: relative;
      z-index: ${topZIndex - 2};
    }
  }

  .navbar-nav .nav-link,
  a {
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 600;
    padding: 0;

    & i {
      display: block;
      font-style: normal;
      padding: 0;
    }
  }

  /* Ul */
  & .navbar-nav {
    max-height: 100px;
    margin: 0;

    & .nav-item {
      padding: 6px 6px 6px 16px;
      margin-bottom: 4px !important;

      @media screen and (min-width: 992px) and (max-width: 1013px) {
        padding: 6px;
      }
    }
    @media (max-width: 991px) {
      margin-bottom: 24px;
      margin: 0;
      padding: 6px;
      max-height: none;
    }
  }

  /* Postman Logo and Home Logo switching */
  & .navbar-brand {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    & .navbar-logo-container {
      width: 32px;
      height: 32px;
      z-index: 9999;
      position: relative;
    }
  }

  & .dropdown-toggle {
    cursor: pointer;
  }

  /* Navbar A tag elements */
  & .nav-link {
    letter-spacing: normal;
    color: #6b6b6b !important;
    font-size: 14px;
    font-weight: 600;
    transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;

    &:hover {
      border-bottom: none;
      color: ${(props) => props.theme.colors.grey_90} !important;
    }

    &:focus {
      outline: none;
    }

    &:link,
    &:active {
      font-weight: 600;
    }
    /* Hide Default Arrow */
    &:after {
      display: none;
    }

    /* SVG Arrow Icon next to Nav Link */
    > i svg {
      display: inline-block;
      margin-left: 6px;
      width: 10px;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      /* Arrow Transitions Up on .show */
      &.show {
        display: inline-block;
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
      }

      @media screen and (max-width: 991px) {
        float: right;
        margin-right: 17px;
        position: relative;
        top: 8px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  /* hamburger mobile menu default */
  button {
    border: none !important;
    border: 0;
    font-size: 24px;
    background: none !important;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  .navbar-toggler-icon {
    width: 24px;
    margin: auto 0;
  }

  span.navbar-toggler-icon {
    background-image: none !important;
  }
  /* hamburger mobile menu icons */
  div.icon-bar {
    position: relative;
    height: 30px;
    width: 30px;
    display: block;
    margin: auto;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 24px;
      background: #6b6b6b;
      border-radius: ${(props) => props.theme.borderRadius.small};
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -ms-transition: all 0.1s ease;
      -webkit-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;
      // line 1 through 4 positions
      &:nth-child(1) {
        top: 10px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 18px;
      }

      &:nth-child(4) {
        top: 26px;
      }
    }
    // line 1 through 4 positions when toggled
    &.open {
      right: 0px;

      span {
        &:nth-child(1) {
          top: 18px;
          left: 50%;
          width: 0%;
          visibility: hidden;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          visibility: hidden;
        }
      }
    }
  }
  /* Individual Dropdown Item */
  & .dropdown-item {
    padding: 0;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 400;

    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colors.grey_05} !important;
      color: #212529;
    }

    /* Get Started for Free link */
    &.app-cta {
      color: ${(props) => props.theme.colors.blue_60};
      font-weight: 600;
    }

    & i {
      padding: 8px 16px;
    }
  }
  @media screen and (max-width: 991px) {
    /* Global Nav */
    #navbarSupportedContent.navbar-collapse {
      top: 47px;
      padding-top: 20px;
      z-index: ${topZIndex - 3};
      width: 100%;
      left: 0;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      background: #ffffff;
      -webkit-transition: height 0.2s linear;
      -o-transition: height 0.2s linear;
      -ms-transition: height 0.2s linear;
      transition: height 0.2s linear;
      &.collapsing {
        position: fixed;
        top: 47px;
      }
      &.show {
        top: 47px;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        height: 100vh;
      }
    }
  }

  & .dropdown-menu {
    /* Mobile Dropdown */
    background-color: ${(props) => props.theme.colors.white};
    border: none;
    padding: 0 0 16px 24px;
    line-height: 20px;
    font-size: 1.4rem;
    /* Web Dropdown */
    @media screen and (min-width: 992px) {
      box-shadow: 0 16px 24px -8px rgb(0 0 0 / 24%);
      background-color: ${(props) => props.theme.colors.white};
      border-radius: ${(props) => props.theme.borderRadius.medium};
      border: 1px solid ${(props) => props.theme.colors.grey_20};
      padding: 16px;
    }
  }
  & .dropdown-col-menu {
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 50rem;
    }
  }
  & .row__short {
    width: 52rem;
  }

  & .dropdown-col {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }

  & .dropdown-header {
    color: #212121;
    font-size: 1.6rem;
    letter-spacing: -0.24px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 0px;
  }
  /* For Mobile - disabled outline on click */
  :-webkit-direct-focus {
    outline-color: none;
    outline-style: auto;
    outline-width: 0;
  }

  #LaunchPostman,
  #SignIn,
  #SignUpforFree {
    padding: 4px 12px;
  }
`;

const CTAs = styled.div`
  @media screen and (max-width: 992px) {
    padding-left: 12px;
  }
`;

/* Sign In Button (White) */
const SignInButton = styled.a`
  align-items: center;
  appearance: none;
  border-radius: ${(props) => props.theme.borderRadius.small};
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.75;
  padding: 4px 12px;
  user-select: none;
  color: ${(props) => props.theme.colors.grey_90} !important;
  background-color: ${(props) => props.theme.colors.white};
  background-image: none;
  border: none;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 24%) inset;
  border-radius: ${(props) => props.theme.borderRadius.small};
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  outline: 0;
  text-align: center;
  margin: 0 8px 0 0;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1px rgb(0 0 0 / 64%) inset;
    text-decoration: none;
  }

  &:focus {
    outline: 0 !important;
  }
  @media screen and (max-width: 1004px) {
    margin: 4px;
  }
`;
/* Sign Up and Launch Postman Buttons (Orange) */
const SwitchCreateAndLaunchButtons = styled.a`
  align-items: center;
  appearance: none;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.small};
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.75;
  padding: 4px 12px;
  user-select: none;
  color: ${(props) => props.theme.colors.white} !important;
  background-color: ${(props) => props.theme.colors.orange_65};
  text-align: center;
  margin: ${(props) => (props.left ? '0 16px 0 0' : '0')};
  transition: background-color 50ms ease-in-out, border-color 50ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.orange_55};
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 480px) {
    display: block;
    margin: 12px 0 0;
  }
  @media screen and (max-width: 992px) {
    margin: 4px;
  }
`;

// Google Analytics Handlers
const handleSignUpClick = (e) => {
  manageDeviceId(e);

  return (
    window.pmt &&
    window.pmt('ga', ['send', 'event', 'global-navbar', 'Click', 'sign-up'])
  );
};

const handleSignInClick = (e) => {
  manageDeviceId(e);

  return (
    window.pmt &&
    window.pmt('ga', ['send', 'event', 'global-navbar', 'Click', 'sign-in'])
  );
};

const handleLaunchPostman = (e) => {
  manageDeviceId(e);

  return (
    window.pmt &&
    window.pmt('ga', [
      'send',
      'event',
      'global-navbar',
      'Click',
      'launch-postman'
    ])
  );
};

// Checks if a user is signed in or logged out
const getCookie = (a) => {
  if (typeof document !== 'undefined') {
    const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : '';
  }
  return false;
};
/* Toggle between Sign In and Launch Postman buttons depending on cookie and environment */
function LoginCheck({ data }) {
  const { cookie, beta, hidden } = data;
  if (!hidden) {
    return (
      <>
        <SignInButton href="/company/contact-sales/">
          <i id="ContactSales" style={{ padding: '4px 12px 4px 12px' }}>
            Contact Sales
          </i>
        </SignInButton>
        <SignInButton
          className={
            cookie !== 'yes'
              ? 'pingdom-transactional-check__sign-in-button mobile-sign-in'
              : 'd-none'
          }
          onClick={handleSignInClick}
          href={`https://identity.getpostman${beta}.com/login?continue=https%3A%2F%2Fgo.postman.co%2Fhome`}
          data-test="SignInLink"
          data-event="headerSignInClicked"
          data-event-prop={`{"cta_link_to":"https://identity.getpostman${beta}.com/login?continue=https%3A%2F%2Fgo.postman.co%2Fhome","cta_style":"primary","cta_text":"Sign In","cta_type":"button","url":"${
            runtime && document.location.pathname
          }"}`}
        >
          <i id="SignIn">Sign In</i>
        </SignInButton>
        <SwitchCreateAndLaunchButtons
          href={
            cookie !== 'yes'
              ? `https://identity.getpostman${beta}.com/signup?continue=https%3A%2F%2Fgo.postman.co%2Fhome`
              : `https://go.postman${beta}.co/redirect/workspace?recentlyVisited=true`
          }
          data-test={cookie !== 'yes' ? 'SignUpButton' : 'launchPostmanButton'}
          data-event={
            cookie !== 'yes' ? 'headerSignUpClicked' : 'headerLaunchAppClicked'
          }
          data-event-prop={
            cookie !== 'yes'
              ? `{"cta_link_to":"https://identity.getpostman${beta}.com/signup?continue=https%3A%2F%2Fgo.postman.co%2Fhome","cta_style":"primary","cta_text":"Sign Up for Free","cta_type":"button","url":"${
                  runtime && document.location.pathname
                }"}`
              : `{"cta_link_to":"https://go.postman${beta}.co/home","cta_style":"primary","cta_text":"Launch Postman","cta_type":"button","url":"${
                  runtime && document.location.pathname
                }"}`
          }
          onClick={cookie !== 'yes' ? handleSignUpClick : handleLaunchPostman}
          className={cookie !== 'yes' ? '' : ''}
        >
          <i id={cookie !== 'yes' ? 'SignUpforFree' : 'LaunchPostman'}>
            {cookie !== 'yes' ? 'Sign Up for Free' : 'Launch Postman'}
          </i>
        </SwitchCreateAndLaunchButtons>
      </>
    );
  }
  return null;
}

class TopNavBarV6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cookie: '',
      beta: '',
      hidden: true,
      data: navbarData // switch to navbarDataLocal for local testing
    };
  }

  componentDidMount() {
    const cookie = getCookie('getpostmanlogin');
    const beta =
      (window.location.host.includes('postman-beta') && '-beta') ||
      (window.location.host.includes('postman-stage') && '-stage') ||
      '';
    this.setState({
      cookie,
      beta
    });

    const navbarKeys = ['items', 'media', 'type'];
    if (navbarKeys.every((key) => Object.keys(featureNavbar).includes(key))) {
      this.setState({
        data: featureNavbar
      });
    } else {
      this.setState({
        data: featureNavbar
      });
    }

    /* eslint-disable react/prop-types */
    const { waitBeforeShow } = this.props;
    /* eslint-enable react/prop-types */
    setTimeout(() => {
      this.setState({ hidden: false });
    }, waitBeforeShow);

    // Toggles Dropdown Menu and Fade Animation
    function showBsDropdown() {
      $(this).find('.dropdown-menu').first().stop(true, true).fadeToggle(250);
      $(this).find('.arrow-icon').addClass('show');
    }
    // Unbinds Dropdown Menu and Fade Animation
    $('.dropdown').on('show.bs.dropdown', showBsDropdown);
    function hideBsDropdown() {
      $(this).find('.dropdown-menu').stop(true, true).fadeToggle(250);
      $(this).find('.arrow-icon').removeClass('show');
    }
    $('.dropdown').on('hide.bs.dropdown', hideBsDropdown);

    if (runtime) {
      const env =
        (!!document.location.host.match('localhost') && 'DEV') ||
        (!!document.location.host.match('beta') && 'BETA') ||
        (!!document.location.host.match('stage') && 'STAGE') ||
        (!!document.location.host.match('preview') && 'PREV') ||
        (!!document.location.host.match('postman.com') && 'PROD');
      const dnt =
        parseInt(navigator.doNotTrack, 10) === 1 ||
        parseInt(window.doNotTrack, 10) === 1 ||
        parseInt(navigator.msDoNotTrack, 10) === 1 ||
        navigator.doNotTrack === 'yes';

      setTimeout(() => {
        const LaunchPostman = document.getElementById('LaunchPostman');
        const hasAmpli = runtime && window.pmt && window.pmt('ampli');

        if (hasAmpli) {
          window.pmt('amplitudeEvent', [
            'headerViewed',
            `{"url":"${document.location.pathname}"}`
          ]);
        }

        window.pmt('set', ['google', googleVar]);

        const pmtGoogle = window.pmt('google');
        const pmtGoogleClient = pmtGoogle && pmtGoogle.client;
        const hasPmtGoogleClients = Object.keys(pmtGoogleClient).length;

        if (!dnt && hasPmtGoogleClients && !LaunchPostman) {
          const SignIn = document.getElementById('SignIn');
          const href =
            (LaunchPostman && LaunchPostman.parentNode.href) ||
            (SignIn && SignIn.parentNode.href);
          SignIn.setAttribute(
            'data-pmGot',
            pmtGoogleClient && pmtGoogleClient[env]
          );
          SignIn.setAttribute('data-topZIndex', topZIndex);
          SignIn.setAttribute('data-pmGot-href', href);

          const script = document.createElement('script');
          const TIME = new Date().getTime();
          script.src = `/mkapi/pmGot.js?${TIME}`;
          script.async = true;
          script.defer = true;

          document.body.appendChild(script);
        }
      }, 500);
    }
  }

  /* eslint-disable class-methods-use-this */
  showTargetElement() {
    // Show Sign In Button if user is not logged in (mobile)
    const cookie = getCookie('getpostmanlogin');
    const signInButton = document.querySelector('.mobile-sign-in');
    if (cookie === 'yes') {
      signInButton.classList.toggle('show');
    } else {
      signInButton.classList.toggle('hide');
    }
    // Global Mobile Icon Transition
    const toggler = document
      .getElementById('globalNav')
      .getAttribute('aria-expanded');
    const body = document.querySelector('body');
    const icon1 = document.getElementById('icon-wrap-one');
    // Mobile Menu is active ?
    if (toggler === 'false') {
      // Add lock CSS to body to disable scroll
      body.classList.add('lock');
      // Flip up dropdown icon
      icon1.classList.add('open');
    } else {
      // Flip down dropdown icon
      icon1.classList.remove('open');
      // Remove lock CSS to body to disable scroll
      body.classList.remove('lock');
    }
    // Hello (bar)
    const messageBarAlertTop = document.getElementById(
      'message-bar-alert-top'
    ) || { style: { display: '' } };
    if (!messageBarAlertTop.style.display) {
      messageBarAlertTop.style.display = 'none';
    } else {
      messageBarAlertTop.style.display = '';
    }
  }

  render() {
    const { cookie, beta, hidden, data } = this.state;

    return (
      <NavbarV6 className="navbar navbar-expand-lg navbar-light bg-light nav-primary">
        <a className="navbar-brand" href="/">
          <div className="navbar-logo-container">
            <img
              src="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
              alt="Postman"
              width="32"
              height="32"
            />
          </div>
        </a>
        <button
          onClick={() => {
            this.showTargetElement();
          }}
          id="globalNav"
          className="mobile-sign-in navbar-toggler"
          data-test="mobileNavToggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <div id="icon-wrap-one" className="icon-bar" aria-expanded="false">
              <span />
              <span />
              <span />
              <span />
            </div>
          </span>
        </button>
        <div id="navbarSupportedContent" className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            {data.items.map((item) => {
              const clickSlug = item.title.split(' ').join('');
              const clickId = `Nav>${clickSlug}`;

              return (
                (item.dropdown && (
                  <li className="nav-item dropdown" key={item.title}>
                    <span
                      className="nav-link dropdown-toggle"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i id={clickId}>
                        {item.title}
                        <svg
                          className="arrow-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="#6b6b6b"
                        >
                          <g>
                            <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                          </g>
                        </svg>
                      </i>
                    </span>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {(item.columns && item.columns && (
                        <div
                          className={
                            item.isWidthShort
                              ? `row row__short`
                              : `row dropdown-col-menu`
                          }
                        >
                          {item.columns.map((col) => (
                            <div
                              className={
                                item.columns.length <= 2
                                  ? 'col-sm-12 col-md-6 dropdown-col'
                                  : 'col-sm-7 col-md-4 dropdown-col'
                              }
                              key={col.title}
                            >
                              <h6 className="dropdown-header">{col.title}</h6>
                              {col.subItemsCol.map((link) => {
                                const linkClickId = `Nav>${clickSlug}>${link.title
                                  .split(' ')
                                  .join('')}`;

                                return (
                                  <a
                                    className="dropdown-item"
                                    href={link.url}
                                    key={link.title}
                                    style={
                                      link.className === 'blue-link'
                                        ? { color: '#0265D2' }
                                        : null
                                    }
                                  >
                                    <i id={linkClickId}>{link.title}</i>
                                  </a>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                      )) ||
                        item.subItems.map((single) => {
                          const title = single.link || single.title;
                          const singleClickId = `Nav>${clickSlug}>${title
                            .split(' ')
                            .join('')}`;

                          return (
                            <a
                              className={`${
                                single.link ? 'app-cta' : ''
                              } dropdown-item`}
                              href={single.url}
                              key={single.title}
                            >
                              <i id={singleClickId}>{single.title}</i>
                            </a>
                          );
                        })}
                    </div>
                  </li>
                )) || (
                  <li className="nav-item" key={item.title}>
                    <a className="nav-link" href={item.url} key={item.title}>
                      <i id={clickId}>{item.title}</i>
                    </a>
                  </li>
                )
              );
            })}
          </ul>
          <CTAs className="form-inline my-2 my-lg-0">
            <LoginCheck
              waitBeforeShow={100}
              data={{ cookie, beta, hidden }}
              className="pingdom-transactional-check__sign-in-button nav-link"
            />
          </CTAs>
        </div>
      </NavbarV6>
    );
  }
}

LoginCheck.propTypes = {
  data: PropTypes.shape({
    cookie: PropTypes.string.isRequired,
    beta: PropTypes.string.isRequired,
    hidden: PropTypes.bool
  }).isRequired
};

export default TopNavBarV6;
